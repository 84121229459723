@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0px;
  overflow: hidden;
}

video {
  max-width: none;
}

h1.heading-1 {
  font-size: 65px;
  font-weight: 300;
  color: white;
  line-height: 3.5rem;
  margin-top: 25px;
  margin-bottom: 25px;
  letter-spacing: -0.1rem;
}
h2.tag {
  font-weight: bold;
  font-size: 42px;
  font-variant: all-small-caps;
  letter-spacing: 0.1rem;
  color: white;
  margin-top: 15px;
  margin-bottom: 15px;
}

span.tag {
  font-weight: bold;
  font-size: 24px;
  font-variant: all-small-caps;
  letter-spacing: 0.05rem;
}
span.abbr {
  font-size: 24px;
  font-variant: all-small-caps;
  line-height: 24px;
  font-weight: 500;
}
p {
  line-height: 24px;
  font-size: 20px;
}

.p {
  line-height: 24px;
  font-size: 20px;
}
.a-tag {
  color: #0e56e5;
  font-weight: 600;
  text-decoration: underline;
}
.font-visiarc-icon {
  font-family: "VISIARC Icons";
}
.card {
  width: 45vw;
  height: 45vw;
}
@media (min-width: 768px) {
  .card {
    width: 22vw;
    height: 22vw;
  }
}
/* Scrollbar */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #ffffff30;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff60;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffffd9;
}

/* Checkbox */
input[type="checkbox"] {
  margin-left: 1px;
  border: 2px solid white;
  border-radius: 2px;
  appearance: none;
  flex-shrink: 0;
  flex-grow: 0;
}
input[type="checkbox"]:checked {
  background-color: rgb(255, 255, 255);
  position: relative;
}
